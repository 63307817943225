import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorPage } from '@/components/ErrorPage';
import { useReturns } from '@/features/returns/hooks/useReturns';

export default function Custom404() {
  const { shopInfo } = useReturns() || {};
  const { t } = useTranslation();

  return (
    <ErrorPage
      title={t('page.error.pageNotFound')}
      message={t('page.description.pageHaveBeenRemoved')}
      redirectUrl={shopInfo?.store_url}
    />
  );
}

// 覆盖掉默认的 Layout
Custom404.getLayout = function getLayout(page: React.ReactElement) {
  return <>{page}</>;
};
